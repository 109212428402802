import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"
import Remoteform from "../components/remoteform"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  padding: 2rem 0 0 0;
`

const Formsupport = styled.form`
  border-top: 20px solid #dadada;
  border-bottom: 20px solid #dadada;
  padding: 4rem 0;
  margin-bottom: 3rem;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
`

const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    grid-column: 1 / 3;
    line-height: 1.8;
    margin-bottom: 4rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      grid-column: 1 / 1;
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1.3;
    }
`

const Quote = styled.div`
  line-height: 1.6;
  font-size: 1.6rem;
  color: #333;

  border-bottom: 1px solid #dadada;
  padding: 1rem 0;
  p {
      padding-bottom: 1rem;
  }
`
const Name = styled.a`
    font-style: normal;
    font-weight: 600;
    color: #333;
    font-size: 1.6rem;
    padding-top: 1rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        color: green;
    }
`

const Button = styled.button`
  padding: 14px 14px;
  background-color: #0D5C00;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 60%;
  
  &:hover {
    background-color:#00B227;
    color: #fff;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
    width: 100%;
  }
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;

  &:hover {
    color: green;
}
`


class ReferenceIndex extends React.Component {
    render() {
      const { location, title, children } = this.props
      const rootPath = `${__PATH_PREFIX__}/`
      let header
  
      return (
        <Layout>
          <SEO
            title="Remote Jobs Newsletter"
            keywords={[`newsletter`, `product design`, `UX design`, `remote`]}
          />
            <img width="100%" src="https://newprag.s3.amazonaws.com/remote/remote-illo.png"/>

            <Intro>
              <Headline>The future is remote</Headline>
              <p>Regardless of how you parse the data, remote design jobs have been <Anchor href="https://www.cnbc.com/2019/06/21/remote-work-doesnt-mean-you-have-to-give-up-a-great-salary.html" target="_blank">steadily on the rise over the past five years</Anchor>. While better connectivity and collaboration tools have played a considerable role in convincing more companies to jump on the remote bandwagon, some companies are true remote pioneers. The companies listed below have fully embraced remote working to both the benefit of the business and its employees. Before applying anywhere, check the listings at these companies first.</p>
            </Intro>

            <Formsupport>
              <h2>Find out who's hiring remotely right now</h2>
              <p>The companies listed below are the best in remote working, but they aren't always hiring. Each week, New Pragmatic sends out a remote newsletter featuring the best remote jobs. Sign up and never miss another great remote opportunity again!</p>
              <Remoteform />
            </Formsupport>

              <Title>18F</Title>
              <Quote>
                  <p>Few companies can boast a more important mission statement. At 18F, they literally try to make government websites better. This passage was lifted directly from their site...</p>
                  <p><i>18F partners with federal agencies to improve the user experience of government services by helping them build and buy technology. If you’re looking to implement a requirement, update a public-facing website, or digitize a process, 18F can work with you to build a product or craft and execute an effective agile acquisition strategy.</i></p>
                  <p>If you can work efficiently and make our live collectively better, we’d love to see you <Anchor href="https://18f.gsa.gov/join/" target="_blank">working at 18F.</Anchor></p>
              </Quote>

              <Title>Automattic</Title>
              <Quote>
                  <p>Boasting team members in 60 countries, the company Automattic may never actually sleep. Which is ideal when your technology powers a significant chunk of the internet.</p>
                  <p>Automattic is the parent company of Wordpress and many other tech offshoots of the blogging platform. While Automattic has always been remote-focused, for a while, they featured a renovated warehouse in San Francisco — <Anchor href="https://qz.com/1002655/the-company-behind-wordpress-is-closing-its-gorgeous-san-francisco-office-because-its-employees-never-show-up/" target="_blank">but hardly anyone showed up for work!</Anchor></p>
                  <p>Like most of the companies listed here, there is more to love about Automattic than just the fact that they are a remote workforce. They also feature great benefits, promote diversity and inclusion, and support open source development.</p>
                  <p>All around, we’re fans. <Anchor href="https://automattic.com/work-with-us/" target="_blank">Bookmark their careers page.</Anchor></p>
              </Quote>

              <Title>Aha!</Title>
              <Quote>
                  <p>Companies built the right way have a few things in common. They tend to be profitable, which means a lot of the industry darlings like WeWork and Uber are completely off the list. They also grow at a controlled rate. This enables a company to maintain a connected culture, even when everyone is working remotely.
                  </p>
                  <p>Those qualities might be hard to find, but Aha! appears to be doing things the right way. Over the years, we’ve seen nothing but steady growth out of the self-funded company — another attribute that puts them in a class of their own.</p>
                  <p>If you dread the churn-and-burn culture of tech, <Anchor href="https://www.aha.io/company/careers/current-openings" target="_blank">apply at Aha! instead.</Anchor>
                  </p>
              </Quote>

              <Title>Wikimedia</Title>
              <Quote>
                  <p>When it comes to great mission statements it is damn hard to beat <i>Work With Us: Make the internet a better place for free knowledge.</i></p>
                  <p>You'd be hard-pressed to find someone who hasn’t used a Wikimedia product in the past week. The job postings aren't always related to their massively popular, Wikipedia. It’s still an easy bet that every position at Wikimedia supports Wikipedia in some way.</p>
                  <p>Yes, you’ll have to explain what you do to relatives at every holiday gathering. Just know you’re mom will always brag to her friends that you work at the online encyclopedia thing. <Anchor href="https://wikimediafoundation.org/about/jobs/" target="_blank">Just bookmark this careers link already.</Anchor>
                  </p>
              </Quote>

              <Title>Zapier</Title>
              <Quote>
                  <p>Only a handful of companies can claim to be the glue that holds the internet together, and Zapier is one of them.</p>
                  <p>The rise of web applications gave us a lot of great tools, but it also created a problem. How to connect all these tools to build customized solutions that make businesses work better. Zapier figured it out, making it easy to connect 100's of web apps — without any coding. Madness.</p>
                  <p>Zapier managed to weave this crazy web magic with a remote team working from over 20 different countries. Not wanting to let go of any of the knowledge gained from that effort, they released a book on remote working that you can <Anchor href="https://zapier.com/learn/remote-work/" target="_blank">download here.</Anchor></p>
                  <p>We're hard-pressed to come up with a reason why you shouldn't apply to work at such an awesome company. <Anchor href="https://zapier.com/jobs/" target="_blank">You definitely want to work at Zapier.</Anchor>
                  </p>
              </Quote>
        </Layout>
      )
    }
  }
  
  export default ReferenceIndex